// home.jsx
import React from 'react';

function Home() {
  return (
    <div className="home-wrapper">
      <h1>歡迎來到Hi-Ms抽獎</h1>
    </div>
  );
}

export default Home;
