import React, { useState, useEffect, useRef } from 'react';
import JsBarcode from 'jsbarcode';
import './Coupon.css';
import parse from 'html-react-parser';

// Import images
import Logo from "./assets/images/logo.png";
import Irent from "./assets/images/irent.png";
import { CryptoHandler } from './cryptoHandler';

function App() {
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const barcodeRef = useRef();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const encryptedRewardId = urlParams.get('RewardId');
  
    if (encryptedRewardId) {
      try {
        const cryptoHandler = new CryptoHandler();
        const decodedRewardId = decodeURIComponent(encryptedRewardId);
        console.log('Decoded RewardId:', decodedRewardId);
  
        const decryptedRewardId = cryptoHandler.decrypt(decodedRewardId);
        console.log('Decrypted RewardId:', decryptedRewardId);
  
        if (decryptedRewardId === '0') {
          // 如果 RewardId 解碼為 0，
          setSelectedCoupon({ 
            type: 'thanks', 
            award: '銘謝惠顧', 
            code: '', 
            event_name: '和運租車 刮刮樂', // Set default event name
            event_subtitle: '2025迎春好禮抽' // Set default event subtitle
          });
        } else {
          fetch(process.env.REACT_APP_API_URL, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ RewardId: decryptedRewardId }),
          })
          .then(response => response.json())
          .then(data => {
            if (data && data.data) {
              // 根據 event_id 判斷 award 顯示內容
              const prizeName = data.data.event_id !== 'MC5' ? '銘謝惠顧' : data.data.prize_name;
  
              // 根據條件設置 code
              const code = data.data.event_id !== 'MC5' ? '' : data.data.code;
              const desc = data.data.desc || '請自行截圖，保存圖檔，以免喪失權益！';
  
              setSelectedCoupon({
                type: decryptedRewardId,
                award: prizeName, // 根據條件設置獎品名稱
                code: code, // 根據條件設置 code 屬性
                desc: desc,
                showCongrats: data.data.event_id === 'MC5', // 添加這個屬性來判斷是否顯示 "恭喜你！獲得"
                event_name: data.data.event_name, // Add event_name
                event_subtitle: data.data.event_subtitle // Add event_subtitle
              });
            }
          })          
          .catch(error => {
            console.error('Error fetching prize:', error);
          });
        }
      } catch (error) {
        console.error('Error decoding RewardId:', error);
      }
    } else {
      // URL 中沒有 RewardId，設定預設值
      setSelectedCoupon({
        type: 'default',
        award: '',
        code: '',
        event_name: '2025新春抽獎活動',
        event_subtitle: '免費租車券、千元折價券等你抽!'
      });
    }
  }, []);
  

  useEffect(() => {
    if (selectedCoupon && barcodeRef.current) {
      // 如果 selectedCoupon.code 為空，不生成條碼
      if (selectedCoupon.code) {
        JsBarcode(barcodeRef.current, selectedCoupon.code, {
          format: "CODE128",
          width: 1.5,
          height: 100,
          displayValue: true
        });
      }
    }
  }, [selectedCoupon]);

  const renderCoupon = (coupon) => {
    // 確保 `award` 和 `desc` 是字串
    const awardContent = typeof coupon.award === 'string' ? coupon.award : '';
    const descContent = typeof coupon.desc === 'string' ? coupon.desc : '';
  
    // 檢查是否不需要生成條碼
    const shouldDisplayBarcode = coupon.award !== "新春抽獎iRent<br/>汽車30分券";
  
    return (
      <div className="coupon-wrapper">
        <div className="coupon">
          <div className="coupon-content">
            {/* 使用 html-react-parser 來安全地解析並渲染 HTML */}
            <div className="coupon-name">{parse(awardContent)}</div>
          </div>
          {/* 根據 shouldDisplayBarcode 的值來決定渲染條碼或預設條碼 */}
          <div className="barcode-svg">
            {shouldDisplayBarcode ? (
              <svg ref={barcodeRef}></svg>
            ) : (
              <div className="barcode"></div>
            )}
          </div>
        </div>
        <div className="coupon-description" style={{ width: '280px' }}>
          {/* 使用 html-react-parser 來安全地解析並渲染 HTML */}
          <div>{parse(descContent)}</div>
          <br />
          序號：{selectedCoupon ? selectedCoupon.code : '載入中...'}
        </div>
      </div>
    );
  };
  
  
  
  
  return (
    <div className="app-wrapper">
      <div className="event-container">
        <div className="lantern lantern-left"></div>
        <div className="lantern lantern-right"></div>
  
        <div className="logo">
          <img src={Logo} width="200px" alt="公司標誌" />
        </div>
  
        <div className="title-main">{selectedCoupon && selectedCoupon.event_name}</div>
        <div className="title-sub">{selectedCoupon && selectedCoupon.event_subtitle}</div>
  
        {/* 在 title-sub 之後顯示恭喜提示，只有在 showCongrats 為 true 時才顯示 */}
        {selectedCoupon && selectedCoupon.showCongrats && (
          <div className="title-congrats">恭喜你！獲得</div>
        )}
  
        {/* 渲染 coupon */}
        {selectedCoupon && renderCoupon(selectedCoupon)}
      </div>

      <footer className="footer">
        <div className="footer-content">
          <h3>注意事項</h3>
          <ul>
            <li>中獎內容不適用春節期間，優惠券使用期限為2025/2/5至2025/5/31</li>
            <li>抽獎後請自行保存中獎圖檔，抽獎人有盡善保管之義務，以免權益喪失</li>
            <li>本活動發放之獎項內容，無法折抵里程費、etag、罰款、逾時費用等其他費用</li>
            <li>所有經由本活動領取之獎項，皆須符合其票券規則方得以兌換與使用，用戶不得要求退款、折換現金及遞延使用</li>
            <li>如有任何疑問，請洽24小時服務專線（02）5589-0808</li>
            <li>和雲行動服務對本內容保有修改、終止、變更方案內容細節之權利</li>
            <li>和運租車之短期租車服務，由和雲行動服務提供</li>
          </ul>

          <hr />

          <h3>和運租車免費租車券、折價券</h3>
          <ul>
            <li>免費租車券限電話/臨櫃預約</li>
            <li>折價券可於和雲行動服務官網預約，於折價券號欄位輸入兌換序號進行折抵</li>
            <li>免費租車券、折價券僅適用於【一般租車專案】，不得使用於其他專案（如包月、票根優惠等等）</li>
            <li>每張券限單次使用，未使用完之餘額不得找零，且不適用於連續假期</li>
          </ul>

          <hr />

          <h3>iRent汽車折抵券</h3>
          <ul>
            <li>iRent折抵券序號請於iRent APP中「優惠券兌換」輸入、兌換並使用</li>
            <li>iRent折抵券不得與企業月結訂單併用，每張券限單次使用，若未使用完剩餘時數即失效</li>
            <li>每筆訂單限用一張，無法多張券合併使用</li>
          </ul>
        </div>
      </footer>
    </div>
  );
}

export default App;
