import CryptoJS from 'crypto-js';

class CryptoHandler {
  constructor() {
    this.secretKey = CryptoJS.enc.Base64.parse(
      process.env.REACT_APP_CRYPTO_SECRET_KEY
    );
    this.iv = CryptoJS.enc.Base64.parse(process.env.REACT_APP_CRYPTO_IV);
  }
  // 為了解決低風險 先註解掉加密的部分 目前專案僅用到解密
  // encrypt(rawData) {  
  //   try {
  //     const encrypted = CryptoJS.AES.encrypt(
  //       rawData,
  //       this.secretKey,
  //       {
  //         iv: this.iv,
  //         mode: CryptoJS.mode.CBC,
  //         padding: CryptoJS.pad.Pkcs7,
  //       }
  //     );
  //     return encrypted.toString();
  //   } catch (error) {
  //     console.error('Encryption error:', error);
  //     return null;
  //   }
  // }

  decrypt(encryptedData) {
    try {
      const decrypted = CryptoJS.AES.decrypt(encryptedData, this.secretKey, {
        iv: this.iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });
      return decrypted.toString(CryptoJS.enc.Utf8);
    } catch (error) {
      console.error('Decryption error:', error);
      return null;
    }
  }
}

export { CryptoHandler };
